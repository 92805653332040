export default {
    model: [
        {
            label: 'Login',
            field: 'login',
            type: 'text'
        },
        {
            label: 'CPF',
            field: 'cpf',
            type: 'text',
            sanitizer: true,
            min: 11
        },
        {
            label: 'Senha',
            field: 'password',
            type: 'text',
            password: true,
            condition_field: 'id',
            condition_type_exists: false
        },
        {
            label: 'Confirmar Senha',
            field: 'confirm_password',
            type: 'text',
            condition_field: 'id',
            condition_type_exists: false
        },
        {
            label: 'Tipo',
            field: 'type',
            type: 'text'
        },
    ]
}