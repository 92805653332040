<template>
  <div class="users">
    <div class="contianer">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{ titlePage }}</div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="form-group col-6">
                    <label>Nome</label>
                    <span class="required-field">*</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.name"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label>Login</label>
                    <span class="required-field">*</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="user.login"
                      v-on:change="verifyUniqueLogin()"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label>Tipo</label>
                    <span class="required-field">*</span>
                    <select v-model="user.type" class="form-control">
                      <option
                        v-for="item in createParams.user_types"
                        :value="item.value"
                        v-bind:key="item.value"
                      >
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-6">
                    <label>CPF</label>
                    <span class="required-field">*</span>
                    <input
                      type="text"
                      class="form-control"
                      v-mask="'###.###.###-##'"
                      v-model="user.cpf"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label>Senha</label>
                    <span class="required-field">*</span>
                    <input
                      type="password"
                      class="form-control"
                      v-model="user.password"
                    />
                  </div>
                  <div class="form-group col-6">
                    <label>Confirme a Senha</label>
                    <span class="required-field">*</span>
                    <input
                      type="password"
                      class="form-control"
                      v-model="user.confirm_password"
                    />
                  </div>
                  <div v-if="user.type == 'operator'" class="form-group col-12">
                    <label> Carteiras </label>
                    <span class="required-field">*</span>
                    <multiselect
                      v-model="selectedWallets"
                      :multiple="true"
                      :options="createParams.wallets"
                      selectLabel="Selecione esse item"
                      deselectLabel="Remova esse item"
                      selectedLabel="Selecionado"
                      track-by="id"
                      label="name"
                      placeholder="Selecione uma carteira"
                    >
                    </multiselect>
                  </div>
                  <div class="col-12">
                    <button
                      class="btn btn-primary float-right btn-submit"
                      v-on:click="save()"
                    >
                      Salvar
                    </button>
                    <button
                      v-if="formType == 'edit'"
                      class="btn btn-primary float-right btn-destroy"
                      v-on:click="destroy()"
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/api/axios";
import Swal from "sweetalert2";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import validator from "@/imports/validator/validate";
import user_validate from "@/imports/validator/models/user";
import user_operator_validate from "@/imports/validator/models/user_operator";
import Multiselect from "vue-multiselect";

export default {
  name: "user",
  components: { Multiselect },
  props: {
    id: Number,
  },
  data() {
    return {
      user: {},
      createParams: {
        user_types: [],
        wallets: [],
      },
      validate:{},
      selectedWallets: [],
      formType: "create",
      titlePage: "Novo Usuário",
      navigate_items: [
        {
          text: "Usuários",
          route: "dashboard.users",
        },
        {
          text: "Criar",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getCreateParams();
    if (this.id) {
      this.formType = "edit";
      (this.navigate_items[1].text = "Editar"), this.getDataObject(this.id);
    }
  },
  methods: {
    getDataObject: function () {
      bus.$emit("changeLoading", true);
      helper.getDataObject("users", this.id).then((response) => {
        this.user = response.data;
        this.titlePage = this.user.name;
        this.selectedWallets = this.user.wallets;
        bus.$emit("changeLoading", false);
      });
    },
    getCreateParams: function () {
      bus.$emit("changeLoading", true);
      helper.getCreateParams("users").then((data) => {
        this.createParams = data.data;
        bus.$emit("changeLoading", false);
      });
    },
    save: function () {
      this.user.wallets = [];
      this.selectedWallets.forEach((el) => this.user.wallets.push(el.id));
      if(this.user.type == "operator"){
        this.validate = user_operator_validate
      }else{
        this.validate = user_validate
      }
      let errors = validator.validate(this.user, this.validate.model);
      if (errors.length == 0) {
        helper
          .saveDataObject("users", this.user, this.formType, "Usuário", "o")
          .then(() => {
            this.$router.push({ name: "dashboard.users" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
    destroy: function () {
      helper.destroyDataObject("users", this.user).then(() => {
        this.$router.push({ name: "dashboard.users" });
      });
    },
    verifyUniqueLogin: function () {
      helper
        .verifyUniqueCred("uniqueCred", this.user.login, "Login")
        .then((response) => {
          if (response.status == 200) {
            this.user.login = "";
          }
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "@/styles/general.scss";
</style>
