export default {
  validate(object, modelValidate) {
    var errors_list = [];
    modelValidate.forEach(element => {
      if (element.condition_field) {
        if (element.condition_type_exists && !object[element.condition_field]) {
          return;
        }
        if (!element.condition_type_exists && object[element.condition_field]) {
          return
        }
      }
      if (!object[element.field]) {
        errors_list.push(
          {
            field: element.field,
            message: `O campo ${element.label} é necessário.`
          }
        );
      }
      else {
        var analizer_item = element.sanitizer ? this.sanitizer(object[element.field]) : object[element.field];

        if (element.min) {
          if (analizer_item.length < element.min) {
            errors_list.push(
              {
                field: element.field,
                message: `O campo ${element.label} esta incompleto.`
              }
            );
          }
        }
        if (element.max) {
          if (analizer_item.length > element.max) {
            errors_list.push(
              {
                field: element.field,
                message: `O campo ${element.label} não esta no formato correto.`
              }
            );
          }
        }
        if (element.type) {
          if (!this[element.type](analizer_item)) {
            if (element.type == 'array') {
              errors_list.push(
                {
                  field: element.field,
                  message: `É necessário inserir ao menos um${element.gender} ${element.label}.`
                }
              );
            }
            else {
              errors_list.push(
                {
                  field: element.field,
                  message: `O campo ${element.label} não esta no formato correto.`
                }
              );
            }
          }
        }
        if (element.password) {
          if (analizer_item != object[`confirm_${element.field}`]) {
            errors_list.push(
              {
                field: element.field,
                message: `As senhas não conferem.`
              }
            );
          }
        }
      }
    });
    return errors_list;
  },
  text(value) {
    return value != '';
  },
  email(value) {
    return value.split('@').length != 1 && value.split('.').length != 1
  },
  cpf(value){
    var cpf = value;
    let sum;
    let rest;

    cpf = cpf.replace(/\D/g, '');

    sum = 0;
    if (cpf == "00000000000") return false;

    for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11)) rest = 0;
    if (rest != parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    rest = (sum * 10) % 11;

    if ((rest == 10) || (rest == 11)) rest = 0;
    if (rest != parseInt(cpf.substring(10, 11))) return false;

    return true;
  },
  array(value) {
    return value.length > 0;
  },
  date(value) {
    //TODO
    return true;
  },
  sanitizer(value) {
    let res = value.replace('-', '');
    res = res.replace('/', '');
    res = res.replace('.', '');
    res = res.replace(' ', '');
    res = res.replace('(', '');
    res = res.replace(')', '');

    return res;
  }
}